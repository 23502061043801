import { Subject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import io from 'socket.io-client';
import Vue from 'vue';
import { useAction, useWebsocketState } from './store.util';

/**
 * this hook expect user to connectToWs and disconnectWs manually
 */

type RoomId = number | string;

interface ListenOnceToOption {
  timeout: number;
}

const SocketClient = io(process.env.VUE_APP_BACKEND_HOSTNAME!, {
  autoConnect: false,
  transports: ['websocket']
});

const activeRoomIds: RoomId[] = [];
const joinRoom$ = new Subject<RoomId>();
joinRoom$
  .pipe(
    distinctUntilChanged(),
    tap((roomId) => activeRoomIds.push(roomId))
  )
  .subscribe((roomId) => {
    SocketClient.emit('JOIN_ROOM', {
      roomId
    });
  });

export function useWebsocket(this: Vue) {
  const socketConnect = useAction.call(this, 'websocket/socketConnect');
  const socketDisconnect = useAction.call(this, 'websocket/socketDisconnect');
  const socketException = useAction.call(this, 'websocket/socketException');
  const add1UsageCount = useAction.call(this, 'websocket/add1UsageCount');
  const minus1UsageCount = useAction.call(this, 'websocket/minus1UsageCount');

  const onConnected = () => {
    if (!useWebsocketState.call(this).isConnected) {
      socketConnect();
      // tslint:disable-next-line
      console.log(`WS connected`);
    }
  };
  const onDisConnected = () => {
    if (useWebsocketState.call(this).isConnected) {
      socketDisconnect();
      // tslint:disable-next-line
      console.log(`WS disconnected`);
    }
  };
  const onException = (e: Error) => {
    socketException(e);
    // tslint:disable-next-line
    console.log(`WS error:`, e);
  };

  const connectToWs = () => {
    return new Promise((resolve, _) => {
      add1UsageCount();
      if (useWebsocketState.call(this).isConnected) {
        resolve(true);
        return;
      }
      SocketClient.open();
      SocketClient.on('connect', onConnected);
      SocketClient.on('disconnect', onDisConnected);
      SocketClient.on('timeout', onException);
      SocketClient.on('error', onException);

      SocketClient.once('connect', resolve);
    });
  };

  const disconnectWs = () => {
    return new Promise((resolve, _) => {
      minus1UsageCount();
      if (
        !useWebsocketState.call(this).isConnected ||
        useWebsocketState.call(this).usageCount > 0
      ) {
        resolve(true);
        return;
      }
      SocketClient.close();
      SocketClient.off('connect', onConnected);
      SocketClient.off('disconnect', onDisConnected);
      SocketClient.off('timeout', onException);
      SocketClient.off('error', onException);

      SocketClient.once('disconnect', resolve);
    });
  };

  // handle joining room
  const joinRoom = (roomId: RoomId) => {
    joinRoom$.next(roomId);
  };
  // handle add removing event listener
  const listenOnceTo = (event: string, option?: ListenOnceToOption) => {
    return new Promise((resolve, reject) => {
      const {
        // 15 seconds
        timeout = 15000
      } = option || {};

      const timer = setTimeout(() => {
        reject(new Error('Event Timeout'));
      }, timeout);

      SocketClient.once(event, (data: any) => {
        clearTimeout(timer);
        resolve(data);
      });
    });
  };

  const removeWorkerJob = useAction.call(this, 'websocket/removeWorkerJob');

  return {
    connectToWs,
    disconnectWs,
    SocketClient,
    removeWorkerJob,
    joinRoom,
    listenOnceTo
  };
}
