import { ImportedEditorDocumentPayload } from './cplus-endpoints/admin/document.types';
import { FullDocumentRevisionObject } from './full-document-revision-object.types';

export enum WorkerJobStatus {
  PENDING = 'Pending',
  PROCESSING = 'Processing',
  PROCESSED = 'Processed',
  FAILED = 'Failed',
  INVALID_TYPE = 'Invalid Type',
}

export interface ImportCitationPayload {
  userId: number;
  projectId: number;
  storageUrl: string;
  revisionId?: number;
}

export type ExportDocumentPayload = FullDocumentRevisionObject;

export interface RootDocIndexingJobPayload {
  revisionId: number;
  document: FullDocumentRevisionObject;
}

export interface RemoveIndexJobPayload {
  revisionId: number;
}

export interface BulkIndexPayload {
  payload: RootDocIndexingJobPayload[];
}

export interface SearchIndexKeywordPayload {
  id?: number;
  keyword: string;
}

export enum CPLUS_BULL_QUEUES {
  'IMPORT' = 'IMPORT',
  'EXPORT' = 'EXPORT',
  'MONITORING' = 'MONITORING',
  'SEARCH_INDEXING' = 'SEARCH_INDEXING',
  'SEARCH_REINDEXING' = 'SEARCH_REINDEXING',
  'CLEAN_UP' = 'CLEAN_UP',
  'INTERNAL' = 'INTERNAL',
  'EVENT_LOG' = 'EVENT_LOG',
  'LEGACY_MIGRATION' = 'LEGACY_MIGRATION',
  'LEGACY_CONVERSION' = 'LEGACY_CONVERSION',
  'SEEDING' = 'SEEDING',
  'PARSE' = 'PARSE',
  'PROJECT' = 'PROJECT',
  'ADMIN' = 'ADMIN',
}

export const CPLUS_BULL_JOBS = {
  [CPLUS_BULL_QUEUES.PROJECT]: {
    UpdateProjectStatusJob: 'UpdateProjectStatusJob',
  },
  [CPLUS_BULL_QUEUES.ADMIN]: {
    CreatePendingDocumentsJob: 'CreatePendingDocumentsJob',
    CreatePendingDocumentJob: 'CreatePendingDocumentJob',
  },
  [CPLUS_BULL_QUEUES.IMPORT]: {
    ImportCitationJob: 'ImportCitationJob',
    ImportEditorDocumentsJob: 'ImportEditorDocumentsJob',
  },
  [CPLUS_BULL_QUEUES.EXPORT]: {
    BulkExportDocumentJob: 'BulkExportDocumentJob',
    BulkExportPDFJob: 'BulkExportPDFJob',
    ExportDocumentJob: 'ExportDocumentJob',
    ExportPDFJob: 'ExportPDFJob',
    GeneratePDFJob: 'GeneratePDFJob',
    WkAggregatedCsvExportJob: 'WkAggregatedCsvExportJob',
    WkAggregatedXmlExportJob: 'WkAggregatedXmlExportJob',
    WkPdfExportJob: 'WkPdfExportJob',
    WkJournalExportJob: 'WkJournalExportJob',
    WkExportStaticLegacyDocumentJob: 'WkExportStaticLegacyDocumentJob',
    SearchExportCsvJob: 'SearchExportCsvJob',
    AdminProjectListPdfJob: 'AdminProjectListPdfJob',
    AdminProjectListXlsxJob: 'AdminProjectListXlsxJob',
    generateAndUploadDocument: 'generateAndUploadDocument',
    copyExistingDocumentToNewStorageURL: 'copyExistingDocumentToNewStorageURL',
    zipAndUploadFiles: 'zipAndUploadFiles',
  },
  [CPLUS_BULL_QUEUES.MONITORING]: {
    WkExportMonitoringJob: 'WkExportMonitoringJob',
    UpdatePublicationStatusMonitorJob: 'UpdatePublicationStatusMonitorJob',
  },
  [CPLUS_BULL_QUEUES.SEARCH_INDEXING]: {
    RootDocIndexingJob: 'RootDocIndexingJob',
    KeywordIndexingJob: 'KeywordIndexingJob',
    DocumentIndexingJob: 'DocumentIndexingJob',
    LatestDocumentIndexingJob: 'LatestDocumentIndexingJob',
    LatestPublishedDocumentIndexingJob: 'LatestPublishedDocumentIndexingJob',
    UpdateDocumentAssigneeIndexingJob: 'UpdateDocumentAssigneeIndexingJob',
    UpdateDocumentStatusIndexingJob: 'UpdateDocumentStatusIndexingJob',
    UpdateDocumentProjectNodeJob: 'updateDocumentProjectNodeJob',
    UpdatePublicationStatusIndexingJob: 'UpdatePublicationStatusIndexingJob',
    BulkUpdatePublicationStatusIndexingJob:
      'BulkUpdatePublicationStatusIndexingJob',
    ProjectIndexingJob: 'ProjectIndexingJob',
    PartialProjectIndexingJob: 'PartialProjectIndexingJob',
    RemoveCriterionsFromIndexByDocumentIdJob:
      'RemoveCriterionsFromIndexByDocumentIdJob',
    IndexCriterionsByDocumentJob: 'IndexCriterionsByDocumentJob',
    CriterionIndexingJob: 'CriterionIndexingJob',
    UserIndexingJob: 'UserIndexingJob',
    UpdateLegacyDocIndexingJob: 'UpdateLegacyDocIndexingJob',
    RemoveDraftIndexByDocumentIdJob: 'RemoveDraftIndexByDocumentIdJob',
  },
  [CPLUS_BULL_QUEUES.SEARCH_REINDEXING]: {
    AllDocumentReIndexingJob: 'AllDocumentReIndexingJob',
    AllUserReIndexingJob: 'AllUserReIndexingJob',
    AuditCriteriaReIndexingJob: 'AuditCriteriaReIndexingJob',
    AllDataReIndexingJob: 'AllDataReIndexingJob',
    RemoveAllRedundantDraftDocumentsJob: 'RemoveAllRedundantDraftDocumentsJob',
  },
  [CPLUS_BULL_QUEUES.CLEAN_UP]: {
    CleanCompletedJobs: 'CleanCompletedJobs',
  },
  [CPLUS_BULL_QUEUES.INTERNAL]: {
    reindexDocByProjectId: 'reindexDocByProjectId',
    addWkExportEventLog: 'addWkExportEventLog',
    getPaginatedLegacyDocumentsByDateRangeForWk:
      'getPaginatedLegacyDocumentsByDateRangeForWk',
    // for manually triggering export job
    exportWkResourceByDateRange: 'exportWkResourceByDateRange',
    exportWkResourceByDocumentId: 'exportWkResourceByDocumentId',
    handleExportJobCreationForSingleLegacyDoc:
      'handleExportJobCreationForSingleLegacyDoc',
    fetchAndPersistsDocumentsByDateRange:
      'fetchAndPersistsDocumentsByDateRange',
    fetchDocumentAndDataByWkExportId: 'fetchDocumentAndDataByWkExportId',
    updateCacheAndStatusForExportedDocument:
      'updateCacheAndStatusForExportedDocument',
    updateExportDocumentStatusFailed: 'updateExportDocumentStatusFailed',
    updateDocumentURLandStatusCompleteForExportProcess:
      'updateDocumentURLandStatusCompleteForExportProcess',
    fetchAndPersistsDocumentByDocumentId:
      'fetchAndPersistsDocumentByDocumentId',
    UpdateCachedDocumentsStatusByWkExportId:
      'UpdateCachedDocumentsStatusByWkExportId',
  },
  [CPLUS_BULL_QUEUES.EVENT_LOG]: {
    updateDocumentAssignee: 'updateDocumentAssignee',
    updateDocumentStatus: 'updateDocumentStatus',
  },
  [CPLUS_BULL_QUEUES.LEGACY_MIGRATION]: {
    // migrate all doc from legacy MSSQL server to c+ postgres
    saveAllInInternalDatabase: 'saveAllInInternalDatabase',
    // migrate single doc from legacy MSSQL server to c+ postgres
    migrateSingleDocToInternalDatabaseByBaseDocumentId:
      'migrateSingleDocToInternalDatabaseByBaseDocumentId',
    // indexing all legacy docs
    indexAll: 'indexAll',
    // indexing single legacy doc
    indexSingleByBaseDocumentId: 'indexSingleByBaseDocumentId',
    markLegacyDocAsMigrated: 'markLegacyDocAsMigrated',
  },
  [CPLUS_BULL_QUEUES.LEGACY_CONVERSION]: {
    nodeQuillConverterConvertHtmlToDelta:
      'nodeQuillConverterConvertHtmlToDelta',
    nodeQuillConverterConvertTextToDelta:
      'nodeQuillConverterConvertTextToDelta',
    convertLegacyDocFormatToNewDocFormat:
      'convertLegacyDocFormatToNewDocFormat',
    extractCitationFromLegacyXml: 'extractCitationFromLegacyXml',
    extractBprFromLegacyXml: 'extractBprFromLegacyXml',
  },
  [CPLUS_BULL_QUEUES.SEEDING]: {
    seedEsForDemo: 'seedEsForDemo',
  },
  [CPLUS_BULL_QUEUES.PARSE]: { parseDocx: 'parseDocx' },
};

export interface CreatePendingDocumentsJobPayload {
  documents: CreatePendingDocumentPayload[];
}

export interface CreatePendingDocumentPayload
  extends ImportedEditorDocumentPayload {
  revisionId?: number;
  textSectionHeaders?: string[];
}

export interface ImportEditorDocumentJobPayload {
  name: string;
  signedUrl: string;
  storageUrl: string;
  storageUri: string;
}

export enum WorkerJobErrorCode {
  FailedToParseDocx = 'FailedToParseDocx',
  MicroserviceError = 'MicroserviceError',
  FailedToRetrieveFileFromStorage = 'FailedToRetrieveFileFromStorage',
  ParseSearchDateError = 'ParseSearchDateError',
  ParseAuthorError = 'ParseAuthorError',
  ParseTextSectionError = 'ParseTextSectionError',
  ParseBprError = 'ParseBprError',
  ParseCriterionError = 'ParseCriterionError',
  ParseReferenceError = 'ParseReferenceError',
  ParseRelatedDocError = 'ParseRelatedDocError',
  ParseNewSectionError = 'ParseNewSectionError',
  MissingSectionTitle = 'MissingSectionTitle',
  MissingSectionValue = 'MissingSectionValue',
  DetectInlineCitationError = 'DetectInlineCitationError',
  ReorderCitationError = 'ReorderCitationError',
  FallbackErrorCode = 'FallbackErrorCode',
}
